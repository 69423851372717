import { useQuery } from '@tanstack/react-query';
import { EXTRACT_PROXY } from '../Constants';

const getVideoPassthrough = (src: string) => {
    return apifetch(EXTRACT_PROXY + src);
}

const apifetch = async (url: string) => {
    const promise = await fetch(url);
    return promise.json();
}

export const useVideoPassthrough = (source: string, enabled: boolean = false) => {
    return useQuery(['useVideoPassthrough', source], () => getVideoPassthrough(source), {
        staleTime: 1000 * 60 * 60, // 60 minutes
        keepPreviousData: true,
        enabled: enabled,
    });
}