import { createRef, useEffect, useState } from "react";
import { VIDEO_PROXY } from "../../Constants";
import { isYTVideoUrl } from "../../Util";
import { useVideoPassthrough } from "../../hooks/useVideoPassthrough";
import loading from "../../assets/Rainbow.gif";
import { useImoLog } from "../Providers/ImoLog/ImoLogProvider";
import Player from "../Player/Player";

interface Props {
    source: string;
    cls: string;
}


export const Video = ({source, cls}: Props) => {
    const [type, setType] = useState<string>("unknown");
    const [passthroughUrl, setPassthroughUrl] = useState<string>("");
    const [ready, setReady] = useState<boolean>(false);

    const video = useVideoPassthrough(source, type === "passthrough");
    const ref = createRef<HTMLVideoElement>();

    const { addLog } = useImoLog();

    useEffect(() => {
        if (isYTVideoUrl(source)) {
            setType("passthrough");
        } else {
            setType("proxy");
        }
    }, [source]);

    useEffect(() => {
        if (video.isSuccess || video.isFetched) {
            setPassthroughUrl(video.data?.url?.replaceAll("\\/", "/").replaceAll(" ", "") || "");
        }
    }, [video.isFetched, video.isLoading, video.isSuccess]);

    useEffect(() => {
        if (video.isError) {
            // switch to error type at some point
            setType("unknown");
            addLog({message: "Error loading video", type: "error"});
            addLog({message: String(video.error)|| "Unknown Error", type: "error"});
        }
    }, [video.isError]);

    const renderLoading = () => {
        // eslint-disable-next-line jsx-a11y/alt-text
        return <div><img src={loading} style={{borderRadius: "50%", width: "32px", height: "32px"}} /></div>
    }

    const renderProxyVideo = () => {
        // Auto play must be enabaled to trigger loadded data and can play events. 
        // Without these events triggering, `ready` will never be set
        return (
            <div>
                {ready ? <></> : renderLoading()}
                <video 
                    className={cls} 
                    ref={ref}
                    controls 
                    autoPlay
                    onCanPlay={() => {setReady(true)}}
                    onLoadedData={() => {setReady(true)}}
                    onCanPlayThrough={() => {setReady(true)}}
                    style={ready ? {} : {display: "none"}}
                >
                    <source src={VIDEO_PROXY + source} type="video/mp4" />
                </video>
            </div>
        );
    }

    const renderPassthroughVideo = () => {
        if (video.isFetching || video.isLoading) {
            return renderLoading();
        }
        // does passthroughUrl have 2 items (new line)
        const lines = passthroughUrl.split("\n");
        return (
            <div>
                {
                    lines.length > 1 ?
                    <Player 
                        url={lines[0]}
                        audioUrl={lines[1]}
                    />
                    :
                    <video 
                        ref={ref}
                        className={cls} 
                        controls 
                        autoPlay
                    >
                        <source src={passthroughUrl} type="video/mp4" />
                    </video>
                }
            </div>
        );
    }

    const types = {
        "proxy": renderProxyVideo,
        "passthrough": renderPassthroughVideo,
        "unknown": () => <div>Unknown Video Type</div>
    }


    // @ts-ignore
    return types[type]();
}