import { useCallback, useEffect, useRef, useState } from "react";

import { useImage } from "../../hooks/useImage";
import loading from "../../assets/Rainbow.gif";
import error from "../../assets/social.png";
import { PROXY } from "../../Constants";
import QuickPinchZoom, { make3dTransformValue } from "react-quick-pinch-zoom";
import { special_img } from "../../Util";


interface ImageProps {
    source: string;
    cls?: string;
    disableZoom?: boolean;
}


interface ScaleProps {
  x: number;
  y: number;
  scale: number;
}

export const Image = ({source, cls = "thumbnail", disableZoom = false}: ImageProps) => {

  const [src, setSrc] = useState<any>(error);

  let url = source;


  if (source?.includes("redd.it") || source?.includes("reddit") || special_img(source)) { 
    source = source.replaceAll("amp;", "");
    url = PROXY + encodeURIComponent(source);
  }
  
  const image = useImage(url);
  
  useEffect(() => { 
    if (image.isLoading || image.isFetching) {
        setSrc(loading);
    } else if (image.isError || !source || !image.data) {
        setSrc(error);
    } else if (image.isSuccess) {
        setSrc(image.data);
    }
  }, [image.isError, image.isLoading, image.isSuccess, image.data, image.isFetching, source]);

  // Handle Zoom/Pinch/Pan
  const imgRef = useRef<any>();
  const onUpdate = useCallback(({ x, y, scale }: ScaleProps) => {
    const { current: img } = imgRef;

    if (img) {
      const value = make3dTransformValue({ x, y, scale });

      // @ts-ignore
      img.style.setProperty("transform", value);
    }
  }, []);

  const img = <img 
        ref={imgRef}
        src={src} alt="" 
        className={cls} 
        style={image.isLoading || image.isError || !image.data ? {width: "32px", height: "32px", marginBottom: "32px", borderRadius: "50%"} : {}} />;
      
  if (disableZoom) {
    return img;
  }
  return (
    <QuickPinchZoom onUpdate={onUpdate}>
      {img}
    </QuickPinchZoom>
  );
}

