import { useQuery } from '@tanstack/react-query';

const getImage = (source: string) => {
    // using fetch to avoid calling the API 
    return fetchBlob(source);
}

const fetchBlob = async (url: string) => {
    const response = await fetch(url);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob)
        reader.onloadend = () => {
            if (blob.size === 0) {
                reject('Image not found');
            } else {resolve(reader.result);}
        }
    });
}

export const useImage = (source: string) => {
    return useQuery(['getCachedImages', source], () => getImage(source), {
        staleTime: 1000 * 60 * 60 * 24 * 3, // 3 days,
        cacheTime: 1000 * 60 * 60 * 24 * 3 ,// 3 days
        retry: false,
        keepPreviousData: true,
        networkMode: 'offlineFirst',
    });
}