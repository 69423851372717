import { Global } from "@emotion/react";
import "./Thread.css";
import { AppBar, Box, Button, CssBaseline, SwipeableDrawer, Typography, styled } from "@mui/material"
import { grey } from "@mui/material/colors";
import { useState } from "react"
import { ThreadContent } from "./ThreadContent";
import { Comments } from "../../Comments/Comments";
import { IoArrowBackOutline, IoShareOutline } from "react-icons/io5";
import { PiMagnifyingGlassMinusLight, PiMagnifyingGlassPlusLight } from "react-icons/pi";
import { LiaExternalLinkAltSolid } from "react-icons/lia";
import { isMobile, isValidUrl } from "../../Util";
import { LiaCommentsSolid } from "react-icons/lia";
import { SHARE_URL } from "../../Constants";


const drawerBleeding = 56;

const mainWin = window;

interface Props {
  window?: () => Window;
  content: string,
  commentsOpen?: boolean,
  threadUrl: string,
  commentCount: number,
  title: string,
  shareImage: string,
  onClose: () => void,
}

const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);
const Root = styled('div')(({ theme }) => ({
    height: '100%',
    width: '100vw',
    backgroundColor:
      theme.palette.mode === 'light' ? grey[100] : theme.palette.background.default,
  }));
  
  const StyledBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
  }));
  
  const Puller = styled(Box)(({ theme }) => ({
    width: 30,
    height: 6,
    backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
    borderRadius: 3,
    position: 'absolute',
    top: 8,
    left: 'calc(50% - 15px)',
  }));

export const Thread = (props: Props) => {
    const { window, commentsOpen, threadUrl, content, commentCount, onClose, title, shareImage } = props;
    const [localCommentCount, setCommentCount] = useState<number>(commentCount);
    const [open, setOpen] = useState<boolean>(commentsOpen || false);
    const [fs, setFs] = useState<number>(14);

    const toggleDrawer = (newOpen: boolean) => () => {
      setOpen(newOpen);
    };
  
    // This is used only for the example
    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Root>
        <CssBaseline />
        <Global
          styles={{
            '.MuiDrawer-root > .MuiPaper-root': {
              height: `calc(95% - ${drawerBleeding}px - var(--sab))`,
              overflow: 'visible',
            },
          }}
        />
        <div>
            <AppBar className={"threadHeader"}>
                <div className="threadControls">
                    <Button onClick={onClose} color="info">
                        <IoArrowBackOutline className="back_arrow"/>
                    </Button>
                    <span style={{textOverflow: "ellipsis", overflow: "hidden", maxHeight: "2em", lineHeight: "1em", display: "block", fontSize: "vw"}}>
                      {title}
                    </span>
                    <div className="subcontrols">
                        {
                          !isMobile() &&
                          <LiaCommentsSolid 
                            className="comments-desktop-button" 
                            onClick={() => {
                              setOpen(!open);
                            }}
                          />
                        }
                        {navigator.canShare && navigator.canShare(
                          {
                            title: title,
                            text: title,
                            url: `${encodeURI(SHARE_URL)}&url=${encodeURI(threadUrl)}&title=${encodeURI(title)}&image=${encodeURI(shareImage)}`,
                          }
                        ) &&
                          <IoShareOutline 
                            className="share" 
                            style={{marginRight: "10px"}}
                            onClick={() => {
                              navigator.share({
                                title: title,
                                text: title,
                                url: `${encodeURI(SHARE_URL)}&url=${encodeURI(threadUrl)}&title=${encodeURI(title)}&image=${encodeURI(shareImage)}`,
                              });
                            }}
                          />
                        }
                        {isValidUrl(content) ?
                          <LiaExternalLinkAltSolid 
                              className="open_in_browser" 
                              onClick={() => {
                                mainWin.open(content, "_blank");
                              }} 
                          />
                          :
                          <>
                            <PiMagnifyingGlassMinusLight className="zoom_out" onClick={() => setFs(fs - 1)} />
                            <PiMagnifyingGlassPlusLight className="zoom_in" onClick={() => setFs(fs + 1)} />
                          </>
                        }
                    </div>
                </div>
            </AppBar>
          <ThreadContent content={content} fs={fs} />
        </div>
        <SwipeableDrawer
          container={container}
          anchor="bottom"
          open={open}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
          swipeAreaWidth={drawerBleeding}
          disableSwipeToOpen={false}
          disableBackdropTransition={!iOS} 
          disableDiscovery={iOS}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <StyledBox
            sx={{
              position: 'absolute',
              top: -drawerBleeding,
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
              visibility: 'visible',
              right: 0,
              left: 0,
              zIndex: 100,
            }}
          >
            <Puller />
            <Typography sx={{ p: 2, color: 'text.secondary' }}>{localCommentCount} comments</Typography>
          </StyledBox>
          <StyledBox
            sx={{
              px: 2,
              pb: 2,
              height: '100%',
              overflow: 'hidden',
            }}
          >
            <Comments 
              permalink={threadUrl} 
              onClosed={() => {
                setOpen(false);
                onClose();
              }}
              countUpdated={(count) => {
                setCommentCount(count);
              }}
            />
          </StyledBox>
        </SwipeableDrawer>
      </Root>
    )
}