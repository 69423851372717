// @ts-nocheck
import { useImoLog } from "../Providers/ImoLog/ImoLogProvider";

const LogViewer = () => {
    const { logs } = useImoLog();

    const logStyle = {
        backgroundColor: "#000000",
        color: "#ffffff",
        padding: "0.5em",
        margin: "0.5em",
        display: "flex",
        width: "95%",
        flexDirection: "row",
        fontFamily: "monospace",
    };

    const typeStyle = {
        width: "50px",
        marginRight: "20px",
        borderRadius: "5px",
        backgroundColor: "#242424",
        padding: "2px",
        textTransform: "uppercase",
        fontSize: "10px",
        textAlign: "center",
        paddingTop: "2px",
        height: "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        
    }
    
    return (
        <div
            style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                background: "#000000",
            }}
        >
            {logs.map((log) => (
                //  @ts-ignore
                <div key={"log-"+log.id} style={logStyle}>
                    <div style={log.type === "error" ? {...typeStyle, backgroundColor: "#ff0000"}: {...typeStyle}}>
                        {log.type}
                    </div>
                    <div style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        width: "100%",
                        height: "30px",
                    }}>
                        {log.message}
                    </div>
                </div>
            ))}
        </div>
    )
}

export default LogViewer;