import { useQuery } from '@tanstack/react-query';
import { axiosClient } from '../AxiosClient';

const getSub = (sub: string, sort: string, range: string) => {
    const promise = axiosClient.get(`r.php?sub=${sub}/${sort}&limit=8&ttime=${range}&after=8`, {});
    return promise;
}

export const useRedditGallery = (url: string) => {
    url = url.replace("/gallery/", "/comments/");
    url = url.replace("https://www.reddit.com", "");
    return useQuery(['useRedditGallery', url], () => getSub(url, "hot", "all"), {
        staleTime: 1000 * 60 * 30, // 30 minutes
        keepPreviousData: true,
    });
}