import { useQuery } from '@tanstack/react-query';
import { axiosClient } from '../AxiosClient';

const getComments = (sub: string) => {
    if (isInnerChommentHash(sub)) {
        sub = sub.replace(getLastPath(sub) + "/", "");
    }
    const promise = axiosClient.get(`r.php?sub=/r/${sub}&limit=8&ttime=all&after=8`, {});
    return promise;
}

export const useComments = (sub: string = "popular") => {
    return useQuery(['getComments', sub], () => getComments(sub.substring(3)), {
        staleTime: 1000 * 60 * 5, // 5 minutes
        keepPreviousData: true,
    });
}

const isStr7CharHash = (str: string) => {
    return str.length === 7 && str.match(/^[a-zA-Z0-9]*$/);
}

const getLastPath = (str: string) => {
    str = str.endsWith("/") ? str.slice(0, -1) : str;
    return str.split("/").pop();
}

const isInnerChommentHash = (str: string) => {
    const last = getLastPath(str);
    return isStr7CharHash(last || "");
}