import { IconButton} from "@mui/material";
import { useComments } from "../hooks/useComments";
import { Comment } from "./Comment";
import "./Comments.css";
import { ImArrowDown, ImArrowLeft, ImArrowUp } from "react-icons/im";
import { LuRefreshCcw } from "react-icons/lu";
import { TfiCommentAlt } from "react-icons/tfi";
import { useEffect, useRef, useState } from "react";
import { isMobile } from "../Util";
import { PiLightning, PiLightningFill } from "react-icons/pi";

interface Props {
    permalink: string,
    onClosed?: () => void,
    countUpdated: (count: number) => void,
}


export const Comments = ({permalink, onClosed, countUpdated}: Props) => {
  const comments = useComments(permalink);
  const [selectedComment, setSelectedComment] = useState<number>(0);
  const [isLive, setIsLive] = useState<boolean>(false);
  let intervalRef = useRef<NodeJS.Timer | null>(null);


  useEffect(() => {
    if (intervalRef && intervalRef.current) clearInterval(intervalRef.current);
    if (!isLive) return;
    intervalRef.current = setInterval(() => {
      if (isLive) {
        comments.refetch();
      }
    }, 10000);

    return () => {
      if (intervalRef && intervalRef.current) clearInterval(intervalRef.current);
    }
  }, [isLive]);

  useEffect(() => {
    if (!isMobile()) window.addEventListener("keydown", bindKeys);

    return () => {
      if (!isMobile()) window.removeEventListener("keydown", bindKeys);
    }
  }, [selectedComment]);

  useEffect(() => {
    document.getElementById(`comment-${selectedComment}`)?.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start" });
    document.getElementsByClassName("selected-comment")[0]?.classList.remove("selected-comment");
    document.getElementById(`comment-${selectedComment}`)?.classList.add("selected-comment");
  }, [selectedComment]);


  useEffect(() => {
    comments.data?.data && countUpdated && countUpdated(comments.data.data[0].data.children[0].data.num_comments);
  }, [comments.data]);

  const bindKeys = (e: KeyboardEvent) => {
    if (e.key === "ArrowDown") {
      setSelectedComment(selectedComment + 1);
    } else if (e.key === "ArrowUp") {
      selectedComment > 0 && setSelectedComment(selectedComment - 1);
    } else if (e.key === "Escape" || e.key === "ArrowLeft") {
      onClosed && onClosed();
    } else if (e.key === "r" || e.key === "ArrowRight") {
      comments.refetch();
    }
  }

  const renderLoading = () => {
    return (
      <div className={'loading-container'}>
        <TfiCommentAlt className={'loading-icon'}/>
        Fetching Comments...
      </div>
    )
  }
  const renderError = () => {
    return <div>Error, could not get comments</div>
  }
  const renderComments = () => {
    if (!comments.data?.data) return renderError();
    return (
      <div>
        <div 
          className="commentsContainer"
          onTouchStart={(e) => {e.stopPropagation()}}
          onClick={(e) => {e.stopPropagation()}}
        >
            {comments.data?.data[1]?.data.children.map((comment: any, id: number) => 
                <Comment 
                  comment={comment.data} 
                  id={`comment-${id}`} 
                  onClicked={(i) => setSelectedComment(i)}/>)}
        </div>
        <div className="commentControls">
          <div>
            <IconButton color="primary" onClick={() => {onClosed && onClosed()}}>
              <ImArrowLeft className={"arrow"}/>
            </IconButton>
          </div>
          <div>
            <IconButton
              color={isLive ? "primary" : "default"}
              onClick={() => {
                setIsLive(!isLive);
              }}
            >
               {
                isLive ?
                  <PiLightningFill className={"live-active"}/> :
                  <PiLightning className={"live-inactive"}/> 
               }
            </IconButton>
            <IconButton color="primary" onClick={() => comments.refetch()}>
              <LuRefreshCcw className={"refresh"}/>
            </IconButton>
            <IconButton color="primary"
              onClick={() => {
                setSelectedComment(selectedComment + 1);
              }}
            >
              <ImArrowDown className={"arrow"}/>
            </IconButton>
            <IconButton color="primary"
              onClick={() => {
                selectedComment > 0 && setSelectedComment(selectedComment - 1);
              }}
            >
              <ImArrowUp className={"arrow"}/>
            </IconButton>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div id="comments">
        {comments.isFetching && !isLive ? renderLoading() : comments.isError ? renderError() : renderComments()}
    </div>
  );
}