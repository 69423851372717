import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import loading from "../../assets/Rainbow.gif";
import { useSummary } from "../../hooks/useSummary";
import "./ArticleSummary.css";
import { Button, Divider } from "@mui/material";
import { Image } from "../Image/Image";
import { isImageUrl } from "../../Util";
import { TO_PDF } from "../../Constants";

interface Props {
    url: string;
    toPdfClick?: () => void;
}


export const ArticleSummary = ({url, toPdfClick}: Props) => {
    const summary = useSummary(url);


    const not_parsable = () => {
        return (
            <div className="not_parsable">
                This article could not be parsed. 
                <Divider />
                <Button variant="contained" size={"small"} color="primary" onClick={() => {window.open(url, "_blank")}}>
                    Open in new window
                </Button>
                <Divider>
                    OR 
                </Divider>
                <Button variant="outlined" size={"small"} color="primary" onClick={() => {window.open("https://txtify.it/"+url, "_blank")}}>
                    Open via txtify.it
                </Button>
                <Button variant="outlined" size={"small"} color="primary" onClick={() => {toPdfClick ? toPdfClick() : window.open(`${TO_PDF}${url}`, "_blank")}}>
                    Convert To PDF
                </Button>
            </div>
        )
    }

    const renderImages = () => {
        if (!summary.data?.images?.filter) return (<></>);
        const images = summary.data?.images?.filter((i: string) => isImageUrl(i));
        if (images) {
            return (
                <div className="articleImages">
                    {
                        summary.data.images.map((img: any, idx: number) => <Image source={img} cls="contentImage" key={`aimg-${idx}`} disableZoom/>)
                    }
                </div>
            );
        }
        return <></>;
    }

    if (summary.isLoading || summary.isFetching) {
        return (
            <img src={loading} alt="" style={{width: "32px", height: "32px", marginBottom: "32px", borderRadius: "50%"}} />
        );
    } else if (summary.isError || !summary.data) {
        return (
            <div>Error, could not get summary</div>
        )
    } else {
        return (
            <div className="summaryContainer">
                <div className="articleSource">
                    source: {url} 
                    <Button 
                        size="small"
                        variant="outlined"
                        style={{alignSelf: "flex-end", marginLeft: "8px"}}
                        onClick={() => {toPdfClick ? toPdfClick() : window.open(`${TO_PDF}${url}`, "_blank")}}>Open as PDF
                    </Button>
                </div>
                {renderImages()}
                {
                    summary.data?.summary ?
                        <ReactMarkdown>{summary.data.summary}</ReactMarkdown>
                    :
                    not_parsable()
                }
            </div>
        );
    }
}