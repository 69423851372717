import { Badge } from "@mui/material";
import PostType from "../../Types/PostType";
import "./Post.css";

import { styled } from '@mui/material/styles';
import { Image } from "../Image/Image";
import { HiUser } from "react-icons/hi";
import { FaCommentAlt } from "react-icons/fa";
import { BiSolidUpvote } from "react-icons/bi";
import { useImoLog } from "../Providers/ImoLog/ImoLogProvider";
import { useEffect } from "react";

const moment = require('moment');

interface PostProps {
    post: PostType, 
    onPostClick?: (post: PostType) => void,
    onUserSelected?: (user: string) => void,
}

const Root = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'light' ? "#ffffff" : "#000000",
    color: theme.palette.mode === 'light' ? "#000000" : "#ffffff",
  }));

export const Post= ({post, onPostClick, onUserSelected}: PostProps) => {
    const { addLog } = useImoLog();

    return (
        <Root 
            className="post"
            key={post.data.id}
        >
            <div className="postMeta" 
                    onClick={() => {
                        onPostClick && onPostClick(post);
                    }}
            >
                <span className="subreddit">r/{post.data.subreddit}</span>
                <Badge badgeContent={post.data.over_18 ? "NSFW": 0} color="error" className="nsfwBadge" anchorOrigin={{vertical: "bottom", horizontal: "right"}}>
                    <Image source={post.data.thumbnail || ""} disableZoom/>
                    {/* <img 
                        src={post.data.thumbnail} 
                        alt="" 
                        className="thumbnail"
                    /> */}
                </Badge>
                <span className="upvoteRatio">
                    <BiSolidUpvote style={{fontSize: "0.75em"}} />
                    {post.data.ups}
                </span>
                <span className="subreddit">{post?.data?.domain || "unknown"}</span>
                {
                        post.data.link_flair_text && 
                        <span className="flair"
                            style={post.data.link_flair_background_color ? {backgroundColor: post.data.link_flair_background_color, color: post.data.link_flair_color} : {}}
                        >{post.data.link_flair_text}</span>
                    }
            </div>
            <div className="postData">
                <Badge badgeContent={post.data.total_awards_received} color="warning" className="awardsBadge" anchorOrigin={{vertical: "top", horizontal: "right"}}>
                    <div className="title" 
                        onClick={() => {
                            onPostClick && onPostClick(post);
                            addLog({
                                type: "info",
                                message: `Post clicked: ${post.data.subreddit}`,
                            });
                        }}
                    >{post.data.title}</div>
                </Badge>
                <div className="subData">
                    <span className="author" onClick={() => onUserSelected && onUserSelected(post.data.author)}>
                        <HiUser />
                        {post.data.author}
                    </span>
                    <span className="comments">
                        <FaCommentAlt />
                        {post.data.num_comments}
                    </span>
                    <span className="time">{moment(post.data.created_utc * 1000).startOf("minute").fromNow()}</span>
                    
                </div>
            </div>
        </Root>
    )
}