
import { Badge, styled, useTheme } from "@mui/material";
import PostType from "../../Types/PostType";
import "./DesktopPost.css";
import { useRef, useState } from "react";
import { Image } from "../Image/Image";
import { BiSolidUpvote } from "react-icons/bi";
import { useIntersection } from "../../hooks/useIntersection";

const moment = require('moment');

interface PostProps {
    post: PostType, 
    onPostClick?: (post: PostType) => void,
    onUserSelected?: (user: string) => void,
}

const Root = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'light' ? "#f7f7f7" : "#24242480",
    color: theme.palette.mode === 'light' ? "#000000" : "#ffffff",
  }));

const DesktopPost = (props: PostProps) => {
    const { post, onPostClick, onUserSelected } = props;
    const [preview, setPreview] = useState<boolean>(false);
    const ref = useRef<HTMLDivElement>(null);
    const isVisible = useIntersection(ref, "-250px");
    const theme = useTheme();
    const isDark = theme.palette.mode === 'dark';

    const previewPost = () => {
        const content =  post?.data?.post_hint === "self"  || post?.data?.domain?.indexOf("self.") !== -1 ? post?.data?.selftext : (post?.data?.url || undefined);
        // an iframe is used to maintain css.  ThreadContent wants to be the full height of the screen, but the post preview should be the height of the content
        // We do the isVisible check in src to prevent safar from preloading every link on the page
        return (
            <iframe src={preview ? `/?play=${content}` : ""} className="post-preview" title="post-preview" />
        );
    }
    
    const thumbnailPost = () => {
        const thumb = post?.data?.preview?.images[0]?.source?.url || post?.data?.thumbnail;
        return (
            <div className={"desktop-post-contentImage-container"}>
                <Image source={thumb} cls="desktop-post-contentImage" disableZoom/>
            </div>
        );
    }

    const darkStyle = {
        backgroundColor: isDark ? "#242424" : "#f7f7f7",
        borderColor: isDark ? "#242424" : "#f7f7f7",
    }

    return (
        <Root 
            className="desktop-post"
            key={post.data.id}
            ref={ref}
        >
            <div className="desktop-post-card"
                style={!isVisible? {opacity: 0.3, ...darkStyle} : {...darkStyle}}
            >
                <div className="desktop-post-card-header"
                    style={post.data.total_awards_received > 0 ? {backgroundColor: "#ffff0020"} : {}}
                >
                    <div>
                        <BiSolidUpvote style={{fontSize: "0.75em"}} />
                        {post.data.ups}
                        {/* nosfw  */}
                        {post.data.over_18 && <span className="desktop-post-card-header-nsfw">NSFW</span>}
                            
                    </div>

                    <div>
                        {post.data.title}
                    </div>
                    <div className="desktop-post-card-header-flair">
                        {
                            post.data.link_flair_text && 
                            <span className="flair"
                                style={post.data.link_flair_background_color ? {backgroundColor: post.data.link_flair_background_color, color: post.data.link_flair_color} : {}}
                            >{post.data.link_flair_text}</span>
                        }
                    </div>
                </div>
                <div 
                    className="desktop-post-card-body"
                    onClick={() => {setPreview(true)}}
                >
                    {preview && isVisible ? previewPost() : thumbnailPost()}
                </div>
                <div className="desktop-post-card-footer">
                    <div className="desktop-post-card-footer-left-subreddit">
                        <span className="subreddit">r/{post.data.subreddit}</span>
                    </div>
                    <div className="desktop-post-card-footer-left-author">
                        <span className="author" onClick={() => {onUserSelected && onUserSelected(post.data.author)}}>u/{post.data.author}</span>
                    </div>
                    <div className="desktop-post-card-footer-left-time">
                        <span className="time">{moment.unix(post.data.created_utc).fromNow()}</span>
                    </div>
                    <div className="desktop-post-card-footer-left-comments" onClick={() => {
                        setPreview(false);
                        onPostClick && onPostClick(post);
                    } }>
                        <span className="comments">{post.data.num_comments} comments</span>
                    </div>
                </div>
            </div>
        </Root>
    );
}

export default DesktopPost;