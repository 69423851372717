import { getUrls } from "../Util";
import { ThreadContent } from "../components/Thread/ThreadContent";
import "./Comment.css";
import ReactMarkdown from 'react-markdown'
const moment = require('moment');

interface Props {
    comment: any,
    id: string,
    onClicked: (id: number) => void,
}


export const Comment = ({comment, id, onClicked}: Props) => {
  if (!comment.body || comment.body === "[deleted]") return (<></>);
  return (
    <div className="comment" id={id} key={`comment-${id}`} onClick={() => onClicked(Number(id.replaceAll("comment-", "")))}>
        <div className="commentMeta">
            <span className="comment_author_cmt"
            onClick={(e) => {
                // copy to clipboard
                navigator.clipboard.writeText(`/user/${comment.author}`);
                // set color to blue
                e.currentTarget.style.color = "blue";
            }}
            >/u/{comment.author}</span>
            <span className="comment_created">{moment(comment.created_utc * 1000).startOf("minute").fromNow()}</span>
        </div>
        <ReactMarkdown 
            className="commentBody"
        >
            {comment.body}
        </ReactMarkdown>
        <div className="commentSubMeta">
            <span className="comment_score">{comment.score} points</span>
        </div>
        <div>
            {
                getUrls(comment.body).map((url: string) => { return <ThreadContent content={url} as_button /> })
            }
        </div>
        <div className="replies">
        {
            comment.replies?.data?.children.map((reply: any, idx: number) => <Comment comment={reply.data} id={`comment-${id}-${idx}`} onClicked={() => onClicked(Number(id.replaceAll("comment-", "")))}/>)
        }
        </div>
    </div>
  );
}