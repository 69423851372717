import "./Footer.css";
import { AppBar, BottomNavigation, BottomNavigationAction, Button, ButtonGroup } from "@mui/material"
import { useState } from "react";

import { AiFillCalendar, AiFillFire } from 'react-icons/ai';
import { ImKeyboard, ImArrowUp, ImArrowLeft } from 'react-icons/im';
import { BsAwardFill, BsCalendarWeekFill } from 'react-icons/bs';
import { GiFlowerEmblem } from 'react-icons/gi';
import { on } from "events";
import { IoToday } from "react-icons/io5";
import { MdCalendarMonth } from "react-icons/md";

interface FooterProps {
  onSortChange: (sort: string) => void;
  onTopRangeChange: (range: string) => void;
}


export const Footer = ({onSortChange, onTopRangeChange}: FooterProps) => {
  const [value, setValue] = useState<string>("hot");
  const [range, setRange] = useState<string>("all");

  const iconStyle = {
    width: "20px",
    height: "20px",
    marginBottom: "5px",
  } as React.CSSProperties;
  
  const miniButtonStyle = {
    maxWidth: "100vw",
    width: "100vw",
  } as React.CSSProperties;
  

  const defaultNav = () =>{
    return (
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
          onSortChange(newValue);
        }}
      >
        <BottomNavigationAction value={"hot"} label="Hot" icon={<AiFillFire style={iconStyle} />} />
        <BottomNavigationAction value={"top"} label="Top" icon={<BsAwardFill style={iconStyle} />} />
        <BottomNavigationAction value={"rising"} label="Rising" icon={<ImArrowUp style={iconStyle} />} />
        <BottomNavigationAction value={"new"} label="New" icon={<GiFlowerEmblem style={iconStyle} />} />
      </BottomNavigation>
    )
  }

  const topNav = () => {
    return (
      <BottomNavigation
        showLabels
        value={range}
        sx={miniButtonStyle}
        onChange={(event, newValue) => {
          if (newValue === "back") {
            setValue("hot");
            setRange("top");
            onTopRangeChange("top");
            onSortChange("hot");
          } else {
            setRange(newValue);
            onTopRangeChange(newValue);
          }
        }}
      >
        <BottomNavigationAction sx={{ minWidth: "10px" }} value={"back"} label="Hot" icon={<ImArrowLeft style={iconStyle} />} />
        <BottomNavigationAction sx={{ minWidth: "10px" }} value={"today"} label="Today" icon={<IoToday style={iconStyle} />} />
        <BottomNavigationAction sx={{ minWidth: "10px" }} value={"week"} label="Week" icon={<BsCalendarWeekFill style={iconStyle} />} />
        <BottomNavigationAction sx={{ minWidth: "10px" }} value={"month"} label="Month" icon={<MdCalendarMonth style={iconStyle} />} />
        <BottomNavigationAction sx={{ minWidth: "10px" }} value={"year"} label="Year" icon={<AiFillCalendar style={iconStyle} />} />
        <BottomNavigationAction sx={{ minWidth: "10px" }} value={"all"} label="All" icon={<BsAwardFill style={iconStyle} />} />
      </BottomNavigation>

    )
  }

  return (
    <div id="footer">
        {value === "top" ? topNav() : defaultNav()}
        {/* <BottomNavigationAction value={"keyboard"} label="" icon={<ImKeyboard style={iconStyle} />} /> */}
    </div>
  );
}