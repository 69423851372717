import { Skeleton } from "@mui/material";
import "./Feed.css";

const FeedSkeley = () => {
    const style = {
        borderRadius: "10px",
    }
    return <div className={"loadingskeley"}>
        <Skeleton variant="rectangular" width="80px" height={80} style={style} />
        <Skeleton variant="rectangular" width="calc(100vw - 100px)" height={80} style={style}/>
    </div>
}

export default FeedSkeley;