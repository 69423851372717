import './App.css';
import { useState, useEffect} from 'react';
import { ThemeProvider } from '@emotion/react';
import { dark_theme, light_theme } from './theme';
import { Header } from './components/Header/Header';
import { Footer } from './components/Footer/Footer';
import { Feed } from './components/Feed/Feed';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import { QueryClient } from '@tanstack/react-query';
import {useQueries as useQueries2} from './hooks/useQueries';
import { Thread } from './components/Thread/Thread';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import {
  createBrowserRouter,
  useParams,
} from "react-router-dom";
import { Drawer, Input, Snackbar } from '@mui/material';
import PostType from './Types/PostType';
import { ThreadContent } from './components/Thread/ThreadContent';
import { PROXY, VERSION } from './Constants';
import { ImoLogProvider } from './components/Providers/ImoLog/ImoLogProvider';
import { IoIosTime } from 'react-icons/io';

const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);
let screenTimeInterval: any= null;
function App() {
  const [dark, setDark] = useState<boolean>(localStorage.getItem("darkMode") === "true");
  const [subreddit, setSubreddit] = useState<string>((localStorage.getItem("rememberLastSubreddit") === "true"  && localStorage.getItem("subreddit")) || "popular");
  const [sort, setSort] = useState<string>("hot");
  const [range, setRange] = useState<string>("all");
  const [openThread, setOpenThread] = useState<boolean>(false);
  const [post, setPost] = useState<PostType | undefined>(undefined);
  const [hasUpdated, setHasUpdated] = useState<boolean>(false);
  const [authenticated, setAuthenticated] = useState<boolean>(localStorage.getItem("authenticated") === "true");
  const [screenTimeLock, setScreenTimeLock] = useState<boolean>(false);
  const [secondsRemaining, setSecondsRemaining] = useState<number>(0);

  const playerMode = useQueries2("play");
    const { path } = useParams();

  useEffect(() => {
    const prevVersion = localStorage.getItem("version");
    if (prevVersion !== VERSION) {
      setHasUpdated(true);
      localStorage.setItem("version", VERSION);
    }

    const mmddyyyy = new Date().toLocaleDateString().replaceAll("/", "-");
    const timeSpent = localStorage.getItem(mmddyyyy) || 0;
    const limit_mins = localStorage.getItem("appTimeLimit");
    const limit_seconds = parseInt(limit_mins || "0") * 60;
    // @ts-ignore
    setScreenTimeLock(parseInt(timeSpent) > limit_seconds && limit_seconds > 0);
    if (screenTimeInterval) clearInterval(screenTimeInterval);
      screenTimeInterval = setInterval(() => {
        const limit_mins = localStorage.getItem("appTimeLimit");
        const timeSpent = localStorage.getItem(mmddyyyy) || 0;

        // @ts-ignore
        localStorage.setItem(mmddyyyy, (parseInt(timeSpent) + 1).toString());

        const limit_seconds = parseInt(limit_mins || "0") * 60;
        // @ts-ignore
        setScreenTimeLock(parseInt(timeSpent) > limit_seconds && limit_seconds > 0);
        // @ts-ignore
        const delta = limit_seconds - parseInt(timeSpent);
        if (delta >= 0) {
          setSecondsRemaining(delta);
        }
      }, 1000);
    window.addEventListener("focus", function() {
      if (screenTimeInterval) clearInterval(screenTimeInterval);
      screenTimeInterval = setInterval(() => {
        const limit_mins = localStorage.getItem("appTimeLimit");
        const timeSpent = localStorage.getItem(mmddyyyy) || 0;

        // @ts-ignore
        localStorage.setItem(mmddyyyy, (parseInt(timeSpent) + 1).toString());

        const limit_seconds = parseInt(limit_mins || "0") * 60;
        // @ts-ignore
        setScreenTimeLock(parseInt(timeSpent) > limit_seconds && limit_seconds > 0);
        // @ts-ignore
        const delta = limit_seconds - parseInt(timeSpent);
        if (delta >= 0) {
          setSecondsRemaining(delta);
        }
      }, 1000);
    });

    window.addEventListener("blur", function() {
      clearInterval(screenTimeInterval);
    });
  }, []);


  /**
   * The app will keep track of how many minutes it has been opened for on a day to day basis.
   * This is to prevent the app from being open for too long and causing the user to miss out on
   * new content.
   */
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        cacheTime: 1000 * 60 * 30,// 30 minutes
        refetchOnMount: false,
        refetchOnWindowFocus: false,
      }
    }
  });


  const persister = createSyncStoragePersister({
    storage: window.localStorage,
  });

  const renderThread = () => {
    const data = post?.data?.post_hint === "self"  || post?.data?.domain?.indexOf("self.") !== -1 ? post?.data?.selftext : (post?.data?.url || undefined)
    const image = post?.data?.thumbnail || "";
    if (!openThread) return <></>;
    return <Thread 
      content={data}
      threadUrl={post?.data.permalink || ""}
      // commentsOpen={!isMobile()}
      commentCount={post?.data.num_comments || 0}
      onClose={() => setOpenThread(false)}
      title={post?.data.title || ""}
      shareImage={ PROXY + encodeURIComponent(image)}
    />;
  }

  const renderFeed = () => {
    return (
      <>
          <Header 
            onBrandClick={() => setDark(!dark)}
            onSubredditChange={(subreddit) => setSubreddit(subreddit)}
            default_sub={subreddit}
            onDarkmodeChange={(mode) => {setDark(mode)}}
            secondsRemaining={secondsRemaining}
          />
          <Feed 
            subreddit={subreddit} 
            sort={sort}
            range={range}
            onUserSelected={(user) => {
              setSubreddit(`/user/${user}`);
            }}
            onPostOpen={(post: PostType) => {
              setOpenThread(true);
              setPost(post);
            }}
          />
          <Footer 
            onSortChange={(sort) => setSort(sort)}
            onTopRangeChange={(range) => setRange(range)}
          />
      </>
    )

  }

  const renderPlayer = () => {
    if (playerMode === null) return <div>Error :(</div>;
    return (
        <ThreadContent
          content={playerMode}
        />
    )
  }


  const authenticate = () => { 
    return (
      <div className="auth">
        <h2>Enter Passcode</h2>
        <Input className="passcode" type='password' placeholder='Password' onChange={(e) => {
          if (e.target.value === "bella") {
            localStorage.setItem("authenticated", "true");
            setAuthenticated(true);
          }
        }} />
      </div>
    )
  }

  const renderApp = () => {
    if (!authenticated) return authenticate();
    if (screenTimeLock) return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          width: "100vw",
          backgroundColor: "black",
          color: "white",
          textAlign: "center",
        }}
      >
        <IoIosTime  style={{
          fontSize: "5rem",
        }}/>
        <h1>Screen Time Limit Reached</h1>
        <h2>Close the app and come back tomorrow</h2>
      </div>
    )
    return (
      <div className="App">
        {renderFeed()}
        <Drawer
          open={openThread}
          className={"open-thread-drawer"}
        >
          {renderThread()}
        </Drawer>
        {/* <InjectionForm /> */}
      </div>
    );
  }

  const renderDefault = () => {
    return playerMode ? renderPlayer() : renderApp()
  }


  const router = createBrowserRouter([
    {
      path: "/r",
      element: renderApp(),
      errorElement: <div>Can't load r :(</div>,
    },
    {
      path: "/",
      element: renderDefault(),
      errorElement: <div>Error :(</div>,
    }
  ]);


  return (
    <ImoLogProvider>
      <PersistQueryClientProvider
        client={queryClient}
        persistOptions={{ persister }}
      >
        <ThemeProvider theme={dark ? dark_theme : light_theme}>
          {playerMode ? renderPlayer() : renderApp()}
          {/* <RouterProvider router={router} /> */}
          {/* <ReactQueryDevtools initialIsOpen={false} /> */}
          <Snackbar
            open={hasUpdated}
            autoHideDuration={6000}
            onClose={() => setHasUpdated(false)}
            message={`imotbo has been updated! ${VERSION}`}
          />
        </ThemeProvider>
      </PersistQueryClientProvider>
    </ImoLogProvider>
  );
}

export default App;

