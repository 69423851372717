import { useQuery } from '@tanstack/react-query';
import { SUMMARY_URL } from '../Constants';

const getSummary = (src: string) => {
    return apifetch(SUMMARY_URL + src);
}

const apifetch = async (url: string) => {
    const promise = await fetch(url);
    return promise.json();
}

export const useSummary = (url: string) => {
    return useQuery(['useSummary', url], () => getSummary(url), {
        staleTime: 1000 * 60 * 30, // 30 minutes
        keepPreviousData: true,
    });
}