import { useState, useEffect } from 'react'
import { isMobile } from '../Util';

export const useIntersection = (element: any, rootMargin: string) => {
    const [isVisible, setState] = useState(isMobile());

    useEffect(() => {
        if (!element || isMobile()) return;
        const ref = element.current;
        const observer = new IntersectionObserver(
            ([entry]) => {
                setState(entry.isIntersecting);
            }, { 
                root: document.body,
                rootMargin,
            }
        );

        ref && observer.observe(ref);

        return () => observer.unobserve(ref);
    }, [element, rootMargin]);

    return isVisible;
};