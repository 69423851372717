export const isImageUrl = (url: string) => {
    // Define regular expressions for common image file extensions
    const imageExtensionsRegex = /\.(jpg|jpeg|png|gif|bmp|svg)$/i;
  
    // Define regular expressions for common image MIME types
    const imageMimeTypesRegex = /^(image\/(jpeg|jpg|png|gif|bmp|svg\+xml)|application\/(x-)?svg\+xml)$/i;
  
    // Check if the URL matches the image extension or MIME type regex
    return imageExtensionsRegex.test(url) || imageMimeTypesRegex.test(url) || special_img(url);
}

export const special_img = (url: string) => {
    if (!url) return false;
    return url.indexOf("preview.redd.it") > -1 
            || url.indexOf(".twimg.") > -1 
            || url.indexOf(".jpg?") > -1 
            || url.indexOf(".jpeg?") > -1 
            || url.indexOf(".png?") > -1 
            || url.indexOf(".gif?") > -1 
            || url.indexOf(".bmp?") > -1 
            || url.indexOf(".svg?") > -1 
            || url.indexOf("phncdn.com/pics") > -1
            ;
}

export const isValidUrl = (url: string) => {
    try {
        new URL(url);
        return true;
    } catch (error) {
        return false;
    }
}

export const isRedditGalleryUrl = (url: string) => {
    if (!isValidUrl(url)) return false;
    if (url.indexOf("reddit.com/gallery") > -1) {
        return true;
    }
    return false;
}

export const isVideoUrl = (url: string) => {
    if (!isValidUrl(url)) return false;
    if (
        url.indexOf("v.redd.it") > -1
        || url.indexOf("youtube.com") > -1
        || url.indexOf("youtu.be") > -1
        || url.indexOf("pornhub") > -1
        || url.indexOf("thisvid") > -1
        || url.indexOf("spankbang") > -1
        || url.indexOf("redgif") > -1
        || url.indexOf("instagram") > -1
        || url.indexOf("vimeo") > -1
        || url.indexOf("soundcloud") > -1
        || url.indexOf("tiktok") > -1
        || url.indexOf("twitter") > -1
        || url.indexOf("facebook") > -1
        || url.indexOf("gfycat") > -1
        || url.indexOf("streamable") > -1
        || url.indexOf("rumble") > -1
        || url.indexOf("twitch") > -1
        || url.indexOf("dailymotion") > -1
        || url.indexOf("vidble") > -1
        || url.indexOf("c-span") > -1
        || url.indexOf("msnbc") > -1
        || url.indexOf("vid.me") > -1
        || url.indexOf("gifv") > -1

    ) {
        return true;
    }
    return false;
}

export const isFrameUrl = (url: string) => {
    if (!isValidUrl(url)) return false;
    return (url.endsWith(".pdf") 
        || url.endsWith(".doc")
        || url.endsWith(".mp3")
        || url.endsWith(".mp4")
        || url.endsWith(".mov")
        || url.indexOf("aspdf=") > -1
    );
}

/**
 * This function has evolved into a general extract url detection function.   Anything
 * that can be extracted (instead of downloaded) should be detected here.
 * @param url 
 * @returns 
 */
export const isYTVideoUrl = (url: string) => {
    if (!isValidUrl(url)) return false;
    if (
        url.indexOf("youtube.com") > -1
        || url.indexOf("youtu.be") > -1
        // || url.indexOf("instagram") > -1
        || url.indexOf("vimeo") > -1
        || url.indexOf("soundcloud") > -1
        || url.indexOf("gifv") > -1
        || url.indexOf("dailymotion") > -1
        // || url.indexOf("instagram") > -1
        || url.indexOf("twitter") > -1
        || url.indexOf("twitch") > -1
        || url.indexOf("thisvid") > -1
        || (localStorage.getItem("useImoPlayer") === "true" && url.indexOf("redd.it") > -1)
        || (localStorage.getItem("useImoPlayer") === "true" && url.indexOf("reddit") > -1)
    ) {
        return true;
    }
    return false;
}

export const isPaywalledUrl = (url: string) => {
    if (!isValidUrl(url)) return false;
    return url.indexOf("nytimes.com") > -1
        || url.indexOf("washingtonpost.com") > -1
        || url.indexOf("wsj.com") > -1
        || url.indexOf("bloomberg.com") > -1
        || url.indexOf("businessinsider.com") > -1
        || url.indexOf("thedailybeast.com") > -1
}

export const getUrls = (text: string)  => {
    // const urlRegex = /((https?|ftp):\/\/[^\s/$.?#].[^\s]*)/gi;
    const urlRegex = /((https?|ftp):\/\/[^\s/$.?#].[^\s$,)(\[\]]*)/gi;
    return text.match(urlRegex) || [];
}


/**
 * function to check if the device is mobile by checking the user
 * agent and various browser info.  Return true if mobile, false if not.
 */
export const isMobile = () => {
    if (typeof navigator === 'undefined') {
        return false;
    }
    const ua = navigator.userAgent;
    return /Android|Mobi/i.test(ua);
}


export const randomHashKey = () => {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
}