import {VERSION as V} from "./Version";

export const VERSION = V;

export const IS_DEV = false 
export const API = IS_DEV ? "https://imotbo.com" : "https://imotbo.com";

export const PROXY = "https://imotbo.com/r2.php?proxy=";
export const TO_PDF= "https://imotbo.com/r2.php?aspdf=";
export const VIDEO_PROXY = "https://imotbo.com/r2.php?redditvideo=";
export const EXTRACT_PROXY = "https://imotbo.com/r2.php?extract=";
// export const SHARE_URL = "https://imotbo.com/?r=";
export const SHARE_URL = "https://imotbo.com/r2.php?share=true&";
export const SUMMARY_URL = "https://imotbo.com/r2.php?smry=";