import { createContext, useContext, useState } from "react";
import { randomHashKey } from "../../../Util";


export interface ImoLog {
    id?: string;
    message: string;
    type?: "error" | "info" | "success" | "warning";
}

export interface ImoLogContextType {
    logs: ImoLog[];
    addLog: (log: ImoLog) => void;
    removeLog: (id: string) => void;
}

const ImoLogContext = createContext<ImoLogContextType | undefined>(undefined);



export const ImoLogProvider = ({children}: {children: React.ReactNode}) => {
    const [logs, setLogs] = useState<ImoLog[]>([]);

    const addLog = (log: ImoLog) => {
        log.id = randomHashKey();
        if (!log.type) {
            log.type = "info";
        }
        if (logs.length > 20) {
            logs.shift();
        }
        setLogs([...logs, log]);
    }

    const removeLog = (id: string) => {
        setLogs(logs.filter((log) => log.id !== id));
    }

    const value = {
        logs,
        addLog,
        removeLog,
    }

    return (
        <ImoLogContext.Provider value={value}>
            {children}
        </ImoLogContext.Provider>
    )
}

export const useImoLog = () => {
    const context = useContext(ImoLogContext);
    if (context === undefined) {
        throw new Error('useImoLog must be used within a ImoLogProvider')
    }
    return context;
}