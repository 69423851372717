import { useQuery } from '@tanstack/react-query';
import { axiosClient } from '../AxiosClient';

const getSub = (sub: string, sort: string, range: string) => {
    const prefix = sub.startsWith("/user") ? "" : "/r/";
    const promise = axiosClient.get(`r.php?sub=${prefix}${sub}/${sort}&limit=8&ttime=${range}&after=8`, {});
    return promise;
}

export const useSubreddit = (sub: string = "popular", sort: string = "hot", range: string = "all") => {
    let exp = 1000 * 60 * 5;  // 5 minutes
    if (sort === "hot") {
        exp = 1000 * 60 * 30;  // 30 minutes
        range = "all"; // hot doesn't support range, don't use cache
    } else if (sort === "top") {
        exp = 1000 * 60 * 60 * 24;  // one day
    } else {
        exp = 1000 * 60 * 5;  // 5 minutes
        range = "all"; // only top supports range, don't use cache
    }
    return useQuery(['getSub', sub, sort, range], () => getSub(sub, sort, range), {
        staleTime: exp,
        keepPreviousData: true,
    });
}


const getPost = (url: string) => {
    let u = url.replace("https://www.reddit.com", "");
    u = u.replace("https://old.reddit.com", "");
    u = u.replace("https://redd.it/", "");
    const promise = axiosClient.get(`r.php?sub=${u}`, {});
    return promise;
}

/**
 * Loads an individual post
 * 
 * @param url 
 * @param enabled 
 */
export const usePost = (url: string, enabled: boolean = false) => {
    return useQuery(['getPost', url], () => getPost(url), {
        staleTime: 1000 * 60 * 5, // 5 minutes
        keepPreviousData: true,
        enabled: enabled,
    });

}