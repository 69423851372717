import { Button, Divider } from "@mui/material";
import { useRedditGallery } from "../hooks/useRedditGallery";
import { Gallery } from "../components/Gallery/Gallery";
import loading from "../assets/Rainbow.gif";

interface Props {
    url: string;
}


export const RedditGallery = ({url}: Props) => {
    const gallery = useRedditGallery(url);

    const extractImages = () => {
        if (!gallery.data || !gallery.data.data) return [];
        const images: string[] = [];
        gallery.data.data[0].data.children.forEach((child: any) => {
            if (child.data.media_metadata) {
                Object.keys(child.data.media_metadata).forEach((key: any) => {
                    const image = child.data.media_metadata[key];
                    images.push(image.s.u);
                });
            }
        });
        return images;
    }

    const not_parsable = () => {
        return (
            <div className="not_parsable">
                Failed to load gallery images.
                <Divider />
                <Button variant="contained" size={"small"} color="primary" onClick={() => {window.open(url, "_blank")}}>
                    Open in new window
                </Button>
            </div>
        )
    }
    
    if (gallery.isLoading || gallery.isFetching) {
        return (
            <img src={loading} alt="" style={{width: "32px", height: "32px", marginBottom: "32px", borderRadius: "50%"}} />
        );
    } else if (gallery.isError || !gallery.data) {
        return (
            not_parsable()
        )
    } else {
        return (
            <div className="redditGallery">
                <Gallery images={extractImages()} />
            </div>
        );
    }
}