import { useEffect, useState } from "react";
import { Image } from "../Image/Image";
import ReactMarkdown from 'react-markdown'
import "./ThreadContent.css";
import { getUrls, isFrameUrl, isImageUrl, isRedditGalleryUrl, isValidUrl, isVideoUrl } from "../../Util";
import { Video } from "../Video/Video";
import { ArticleSummary } from "../ArticleSummary/ArticleSummary";
import { RedditGallery } from "../../RedditGallery/RedditGallery";
import { Button, SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material";
import { PROXY, TO_PDF } from "../../Constants";
import { useImoLog } from "../Providers/ImoLog/ImoLogProvider";

interface Props {
    content: string;
    as_button?: boolean;
    fs?: number;
}

export const ThreadContent= ({content, as_button = false, fs = 14}: Props) => {
    const [type, setType] = useState<string>("unknown");
    const [open, setOpen] = useState<boolean>(false);
    const [textAttachments, setTextAttachments] = useState<boolean>(false);

    const { addLog } = useImoLog();


    useEffect(() => {
        addLog({
            message: content+ " loaded as: " + type,
        });
    }, [type]);

   const renderContentAsImage = () => {
        return <Image source={content || ""} cls="contentImage" />;
   }

   const renderContentAsText = () => {
        return (
            <div
                style={{fontSize: fs+ "px", width: "100vw", overflow: "hidden"}}
            >
                {
                    textAttachments ?
                        <div className="contentText">
                            {
                                getUrls(content).map((url: string) => { return <ThreadContent content={url} as_button /> })
                            }
                        </div>
                    :
                        <ReactMarkdown 
                            className="contentText"
                        >
                            {content}
                        </ReactMarkdown>
                }
                <div className="contentAttachments">
                    {
                        getUrls(content).length > 0 &&
                        <Button variant="contained" size={"small"} color="primary" onClick={() => {setTextAttachments(!textAttachments)}}>
                            {textAttachments ? "Hide" : "Show"} Attachments
                        </Button>
                    }
                </div>
            </div>
        );
   }

   const renderUnknown = () => {
    return <div className="contentUnknown">Unknown Content Type</div>;
   }

   const renderContentAsUrl = () => { 
    return (
        <ArticleSummary url={content} toPdfClick={() => setType("convert_to_pdf")}/>
    )
   }

   const renderContentAsVideo = () => {
    return (
        <div className="contentVideoContainer">
            <Video source={content} cls="contentVideo" />
        </div>
    );
   }

   const renderContentAsButton = () => {
    return (
        <div>
            <button className="contentButton" onClick={() => window.open(content)}>Click to open in new window</button>
        </div>
    )
   }

    const renderAsRedditGallery = () => {
        return (
            <RedditGallery url={content} />
        );
    }

    const renderInFrame = () => {
        return (
            <iframe src={`${PROXY}${encodeURI(content)}`} className="imoframe" title={"imoframe"}  />
        );
    }

    const renderViaTxtify = () => {
        // Always has the potential to break
        return (
            <iframe src={"https://txtify.it/"+content} className="imoframe" title={"imoframe"}   />
        );
     }

     const renderRedditEmbed = () => {
        let sub = content.split("/r/")[1];
        return (
            <iframe src={`https://imotbo.com/?play=/r/${encodeURI(sub)}`} className="imoframe" title={"imoframe"}   />
        )
     }

     const renderAsPdfConversion = () => {
        return (
            <iframe src={`${TO_PDF}${content}`} className="imoframe" title={"imoframe"}   />
        )
     }

   const types = {
         "image": renderContentAsImage,
         "text": renderContentAsText,
         "url": renderContentAsUrl,
        //"url": renderContentAsButton,
         "framable": renderInFrame,
         "textify": renderViaTxtify,
         "video": renderContentAsVideo,
         "unknown": renderUnknown,
         "reddit_gallery": renderAsRedditGallery,
         "reddit_embed": renderRedditEmbed,
         "button": renderContentAsButton,
         "convert_to_pdf": renderAsPdfConversion,
   }
   useEffect(() => {
        if (content) {
            if (isImageUrl(content)) {
                setType("image");
            } else if (isRedditGalleryUrl(content)) {
                setType("reddit_gallery");
            } else if (isVideoUrl(content)) {
                setType("video");
            } else if (isFrameUrl(content)) {
                setType("framable");
            } else if (content && content.indexOf("reddit.com/r/") > -1) {
                setType("reddit_embed");
            // } else if (isPaywalledUrl(content)) {
                // setType("textify");
            // ALL OTHER URL TYPES MUST BE CHECKED FIRST
            } else if(isValidUrl(content)) {
                setType("url");
            } else {
                setType("text");
            }
        }
    }, [content]);

  return (
    <div id="threadContent" style={as_button  ? {height: "inherit"} : {}}>
        {
            as_button && !open ?
                <button className="contentButton" onClick={() => setOpen(true)}>{content}</button>
            :
            // @ts-ignore
            types[type]()
        }
        {/* <SpeedDial 
            ariaLabel={"speed-dial"} 
            icon={<SpeedDialIcon />}
            sx={{ position: 'absolute', bottom: -10, right: 16 }}
        >
            {
                Object.keys(types).map((key: string) => {
                    return <SpeedDialAction key={`speed-dial-${key}`} icon={<SpeedDialIcon />} tooltipTitle={key} onClick={() => setType(key)} />
                })
            }
        </SpeedDial> */}
    </div>
  );
}