
import { Button, Divider } from "@mui/material";
import { useState } from "react";
import { Image } from "../Image/Image";
import "./Gallery.css";

interface Props {
    images: string[];
}


export const Gallery = ({images}: Props) => {
    const [index, setIndex] = useState<number>(0);

    const renderImage = () => {
        return (
            <Image source={images[index]} cls="galleryImage" />
        )
    }

    const renderControls = () => {
        return (
            <div className="galleryControls">
                <Button variant="contained" size={"small"} color="primary" onClick={() => {setIndex(index - 1)}} disabled={index === 0}>
                    Prev
                </Button>
                <Button variant="contained" size={"small"} color="primary" onClick={() => {setIndex(index + 1)}} disabled={index === images.length - 1}>
                    Next
                </Button>
            </div>
        )
    }

    return (
        <div>
            {renderImage()}
            <Divider />
            {renderControls()}
        </div>
    )
    
}